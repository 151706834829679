import { useEffect } from 'react';
import WOW from 'wowjs';
import Footer from '../common/Footer';
import './home.css';
import './../../../assets/css/colate.css';
import './../../../assets/css/style.css';
import './../../../assets/css/animate.css';
import img001 from './../../../assets/img/meet-colate-lines.png';
import './../../../assets/css/bootsnav.css'
import Owlemerging from '../common/Owlemerging';
import SideNavOn from '../../../assets/js/sidenavon.js';
import BannerSlider from './../common/Bannerslider';
import Realesatate from '../common/Realestate';



function Home() {
  useEffect(() => {
    const wow = new WOW.WOW({live: false});
    wow.init({
      duration: 1000
    });
    
  }, []);
  useEffect(() => {
    document.body.classList.add('signin');
  });
  return (
    <>
      <SideNavOn />
      <BannerSlider />



      <div className="bg-salmon about-area overflow-hidden default-padding ptslider1 section-padding402">
        <div className="container">
          <div className="about-items">
            <div className="row align-center">
              {/* Thumb */}
              <div className="col-lg-6 wow bounceInUp">
                <div className="thumb">
                  <img src={img001} alt="Thumb" />
                </div>
              </div>
              {/* End Thumb */}
              <div className="col-lg-6 mtnegative50 wow bounceInUp">
                <div className="content">
             
                  <h1 className="mb-2 text-black-40 text-left">About Us</h1>
                  <h3 className="mb-2 text-black-40 text-left mt-5">
                  Welcome to COLATE, where innovation meets passion.
                  </h3>
                  <p className="mb-2 text-black-40 text-left mt-5">
                    We are a team of dedicated individuals driven by a shared vision:
                    To revolutionize latest services and products through cutting-edge solutions.
                  </p>
                  <p className="mb-2 text-black-40 text-left">
                    At our core, we believe in the power of creativity and collaboration.
                  </p>
                  <h1 className="mb-2 text-black-40 text-left mt-5">Our Story</h1>
                  <p className='mt-5'>
                      Our journey began when a group of like-minded individuals came
                      together with a shared passion for technology and a desire to
                      create something truly special.
                  </p>
                  <p>
                     Our team has expanded, broadening our capabilities and
                     empowering us to provide state-of-the-art services to our clients.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="uniqueness" className="main-services-area overflow-hidden section-padding40 shape-layout default-padding1 1ptslider bottom-less">
        <div className="container">
          <div className="heading-left">
            <div className="row">
              <div className="col-lg-5  wow bounceInRight">
              <h1 className="mb-2 text-black-40 text-left">
                  What sets us apart?
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="main-services-box">
            <div className="row">
              {/* Single Item */}
              <div className="single-item col-lg-6 col-md-6 heading-left wow bounceInLeft">
                <div className="item">
                  <div className="icon">
                    <i className="flaticon-3d" />
                  </div>
                  <div className="info">
                    <h4 className="mb-2 text-black-40 text-left">
                      Expertise in Emerging Technologies
                    </h4>
                    <p>
                      Our team of skilled professionals possesses deep expertise in the latest emerging technologies,
                      including artificial intelligence, blockchain, Internet of Things (IoT),
                      and augmented reality which empowers us to craft tailored solutions
                      that meet the evolving needs of our clients.
                    </p>
                  </div>
                </div>
              </div>
              {/* End Single Item */}
              {/* Single Item */}
              <div className="single-item col-lg-6 col-md-6 heading-left wow bounceInLeft">
                <div className="item">
                  <div className="icon">
                    <i className="flaticon-target" />
                  </div>
                  <div className="info">
                    <h4 className="mb-2 text-black-40 text-left">
                      Innovation at the Core
                    </h4>
                    <p>
                      Innovation is our guiding principle. We continuously explore new horizons, experiment with novel ideas,
                      and develop groundbreaking applications. We create solutions that not only meet today's challenges but also anticipate tomorrow's demands.
                    </p>
                  </div>
                </div>
              </div>
              {/* End Single Item */}
              {/* Single Item */}
              <div className="single-item col-lg-6 col-md-6 heading-left">
                <div className="item">
                  <div className="icon">
                    <i className="flaticon-price-tag" />
                  </div>
                  <div className="info">
                    <h4 className="mb-2 text-black-40 text-left">
                      Collaborative Partnership
                    </h4>
                    <p>
                      We believe in collaborative partnerships that foster innovation.
                      Working closely with our clients, we co-create solutions tailored to their unique challenges.
                      Your insights combined with our technical prowess result in solutions that drive your business forward.
                    </p>
                  </div>
                </div>
              </div>
              {/* End Single Item */}
              {/* Single Item */}
              <div className="single-item col-lg-6 col-md-6 heading-left wow bounceInRight">
                <div className="item">
                  <div className="icon">
                    <i className="flaticon-bullhorn" />
                  </div>
                  <div className="info">
                    <h4 className="mb-2 text-black-40 text-left">
                      Adaptive Solutions for a Dynamic World
                    </h4>
                    <p>
                      In a rapidly changing digital landscape, adaptability is key. We excel in creating adaptive solutions that seamlessly integrate emerging technologies into existing infrastructures. Our solutions evolve with your business, ensuring long-term relevance and efficiency.
                    </p>
                  </div>
                </div>
              </div>
              {/* End Single Item */}
            </div>
          </div>
        </div>
      </div>
      <Realesatate/>
      <div className="case-studies-area default-padding-bottom pt176 wow bounceInRight">
        <div className="container container-home1">
          <div className="row row-home">
            <div className="col-lg-6 offset-lg-3">
              <div className="heading-center text-center">
              <h1 className="mb-2 text-black-40 text-left">Our Solutions</h1>
              </div>
            </div>
          </div>
        </div>
        <Owlemerging />
      </div>
      <Footer />
    </>
  )
}

export default Home