import React from 'react';
import { Routes, Route, Link } from "react-router-dom";
import AddPage from './pages/admin/AddPage';
import Home from './pages/UI/home/home'
import Uniqueness from './pages/UI/common/Uniqueness';
import Vision from './pages/UI/common/Vision';
import Mission from './pages/UI/common/Mission';
import Strategy from './pages/UI/common/Strategy';
import EmergingTechnologies from './pages/UI/common/EmergingTechnologies'
import Web3 from './pages/UI/common/Web3'
import Blockchain from './pages/UI/common/Blockchain'
import Xrvr from './pages/UI/common/Xrvr'
import CloudInfrastructure from './pages/UI/common/CloudInfrastructure'
import DevopsAutomation from './pages/UI/common/DevopsAutomation';
import DevopsRelease from './pages/UI/common/DevopsRelease';
import DevopsMatrics from './pages/UI/common/DevopsMetrics';
import Fintech from './pages/UI/common/Fintech';
import Telecommunication from './pages/UI/common/Telecommunication';
import Healthcare from'./pages/UI/common/Healthcare';
import Contact from './pages/UI/common/Contact';
import Automation from './pages/UI/common/Automation';
import AllBlog from './pages/admin/AllBlogs/AllBlog';
import Category from './pages/admin/Category';
import Author from './pages/admin/Author';
import Whitepaper from './pages/UI/common/Whitepaper';
import ApplicationDevlopment from './pages/UI/common/ApplicationDevlopment';
import Dashboard from './pages/admin/Dashboard/Dashboard';

import VisionReality from './pages/UI/common/VisionReality';
import Industries from './pages/UI/common/Industries';
import Services from './pages/UI/common/Services';
import HardwareEngineering from './pages/UI/common/HardwareEngineering';

import Login from './pages/admin/Login';
import Blogs from './pages/admin/Blogs';
import Realestate from './pages/UI/common/Realestate';
import Cocreate from './pages/UI/common/Cocreate';
import Signup from './pages/UI/common/SignUp';
import Whitepaperlisting from './pages/UI/common/WhitepaperListing';
import SoftwareDevelopment from './pages/UI/common/SoftwareDevelopment';
import TabsContent from './pages/UI/common/TabsContent';
import TabList from './pages/UI/common/TabList';
import ToastList from './pages/UI/common/ToastList';
import SlideWow from './pages/UI/common/SlideWow'

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/admin" element={<Dashboard />} />
        <Route path="/category" element={<Category />} />
        <Route path="/author" element={<Author />} />
        <Route path="/addpage" element={<AddPage />} />
        <Route path="/blogs" element={<AllBlog />} />
        <Route path="/blog/:id" element={<Blogs />} />
        <Route path="/Uniqueness" element={<Uniqueness />} />
        <Route path="/Vision" element={<Vision />} />
        <Route path="/Mission" element={<Mission />} />
        <Route path="/Strategy" element={<Strategy />} />
        <Route path="/EmergingTechnologies" element={<EmergingTechnologies />} />
        <Route path="/Web3" element={<Web3 />} />
        <Route path="/Blockchain" element={<Blockchain />} />
        <Route path="/Xrvr" element={<Xrvr />} />
        <Route path="/CloudInfrastructure" element={<CloudInfrastructure />} />
        <Route path="/DevopsAutomation" element={<DevopsAutomation />} />
        <Route path="/DevopsRelease" element={<DevopsRelease />} />
        <Route path="/DevopsMetrics" element={<DevopsMatrics />} />
        <Route path="/Fintech" element={<Fintech />} />
        <Route path="/Telecommunication" element={<Telecommunication />} />
        <Route path="/Healthcare" element={<Healthcare />} />
        <Route path="/Automation" element={<Automation />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Whitepaper" element={<Whitepaper />} />
        <Route path="ApplicationDevlopment" element={<ApplicationDevlopment />} />
        <Route path="VisionReality" element={<VisionReality />} />
        <Route path="Industries" element={<Industries />} />
        <Route path="Services" element={<Services />} />
        <Route path="HardwareEngineering" element={<HardwareEngineering />} />
        <Route path="Realestate" element={<Realestate/>} />
        <Route path="Cocreate" element={<Cocreate/>} />
        <Route path="Signup" element={<Signup/>} />
        <Route path="Whitepaperlisting" element={<Whitepaperlisting/>} />
        <Route path="SoftwareDevelopment" element={<SoftwareDevelopment/>} />
        <Route path="TabsContent" element={<TabsContent/>} />
        <Route path="TabList" element={<TabList/>} />
        <Route path="ToastList" element={<ToastList/>} />
        <Route path="SlideWow" element={<SlideWow/>} />
      </Routes>
    </div>
  );
}

export default App;
