import React from 'react';
import { Link } from 'react-router-dom';

import release1 from './../../../assets/img/gallery/machine_hand.png';
import release1mob from './../../../assets/img/gallery/machine_hand-mob.png';
import release2 from './../../../assets/img/gallery/banner_slide_1.png';
import release2mob from './../../../assets/img/gallery/banner_slide_1_mob.png';
import release3 from './../../../assets/img/gallery/brain_ai.png';
import release3mob from './../../../assets/img/gallery/brain_ai_mob.png';
import release4 from './../../../assets/img/gallery/ai-slider-3.png';
import release4mob from './../../../assets/img/gallery/ai-slider-3-mob.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong, faLongArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import './../../../../src/assets/css/topslider.css'
import { Navigation, Autoplay } from 'swiper/modules';

const BannerSlider = () => {


  return (

    <div className='container-fluid mySwiper ptsliderw'>

      <div className='container'>
        <Swiper navigation={true} autoplay={true} modules={[Autoplay, Navigation]} className="homeslidertop marStyle" >
          <SwiperSlide>
            <div className='flex-item-slider vh100w offset-lg-0 col-lg-5 col-sm-4 col-xs-12'>

              {/* <h2 data-animation="animated slideInRight" className="">
                Our Mission
              </h2> */}
              <div className='1wrapvh'>
                <h1 className="gridenttext colorh1"> Collaborate<br /> Innovate<br /> Solve</h1>
                <p data-animation="animated slideInLeft mobtop50">
                  Together, we pioneer solutions for a brighter tomorrow, crafting exceptional software innovations that transform ideas into reality and empower businesses to thrive in the digital age
                </p>

                {/* <p data-animation="animated slideInLeft mobtop50">
                Unleashing innovation, AI paints the future's canvas with intelligence unseen. It's the tech wizardry, reshaping industries, revolutionizing our world's machine dream.
                </p> */}
              </div>
              {/* mobile version show image */}

              <img
                style={{ width: '100%', marginBottom: '3px' }}
                src={release1mob}
                alt="Thumb" className='img-fluid no-shrink d-none1  mobshow'
              />


              {/* mobile version show image end*/}

            </div>
            <div className='flex-item-slider imginfogra vh100 p25w offset-lg-0 col-lg-7 col-sm-5 col-xs-12'>
              <img
                style={{ width: '100%' }}
                src={release1}
                alt="Thumb" className='img-fluid no-shrink d-none d-sm-block'
              /></div>
          </SwiperSlide>
          <SwiperSlide>

            <div className='flex-item-slider vh100w offset-lg-0 col-lg-5 col-sm-4 col-xs-12'>

              {/* <h2 data-animation="animated slideInDown">
    Collaborate, Innovate, Solve </h2> */}
              <div className='1wrapvh'>
                <h1 className="gridenttext colorh1">Artificial Intelligence</h1>
                <p data-animation="animated slideInLeft marginbottom-xs">Artificial Intelligence, the digital sentinel, transforms data into insights, navigating the realms of possibility with computational prowess.</p>
              </div>
              {/* mobile version show image */}

              <img
                style={{ width: '100%', marginBottom: '17px', marginTop: '38px' }}
                src={release4mob}
                alt="Thumb" className='img-fluid no-shrink mobshow'
              />

              {/* mobile version show image end*/}
              {/* <h1 className="mb-2 text-black-40w text-left gridenttext"> Collaborate, Innovate, Solve </h1> */}

              {/* <a
    className="btn-animation border dark smooth-menu"
    href="#uniqueness"
    data-animation="animated slideInUp"
  >
    Read more <FontAwesomeIcon icon={faLongArrowRight} />
  </a> */}
            </div>
            <div className='flex-item-slider imginfogra p25w offset-lg-0 col-lg-7 col-sm-5 col-xs-12'>

              <img
                style={{ width: '100%' }}
                src={release4}
                alt="Thumb" className='img-fluid no-shrink d-none d-sm-block'
              /></div>
          </SwiperSlide>
          <SwiperSlide>

            <div className='flex-item-slider vh100w offset-lg-0 col-lg-5 col-sm-4 col-xs-12'>

              {/* <h2 data-animation="animated slideInDown">
                Collaborate, Innovate, Solve </h2> */}
              <div className='11wrapvh'>
                <h1 className="gridenttext colorh1">Fintech</h1>
                <p data-animation="animated slideInLeft marginbottom-xs">the financial revolution's heartbeat, melds tech prowess with monetary streets. Innovating transactions, it unlocks a future where finance and technology meet.</p>
              </div>
              {/* mobile version show image */}

              <img
                style={{ width: '100%', marginBottom: '17px', marginTop: '38px' }}
                src={release2mob}
                alt="Thumb" className='img-fluid no-shrink mobshow'
              />

              {/* mobile version show image end*/}
              {/* <h1 className="mb-2 text-black-40w text-left gridenttext"> Collaborate, Innovate, Solve </h1> */}
              {/* <p data-animation="animated slideInLeft">
                Together, we pioneer solutions for a brighter tomorrow, crafting exceptional software innovations that transform ideas into reality and empower businesses to thrive in the digital age
              </p> */}
              {/* <a
                className="btn-animation border dark smooth-menu"
                href="#uniqueness"
                data-animation="animated slideInUp"
              >
                Read more <FontAwesomeIcon icon={faLongArrowRight} />
              </a> */}
            </div>
            <div className='flex-item-slider imginfogra p25w offset-lg-0 col-lg-7 col-sm-5 col-xs-12'>

              <img
                style={{ width: '100%' }}
                src={release2}
                alt="Thumb" className='img-fluid no-shrink d-none d-sm-block'
              /></div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='flex-item-slider vh100w offset-lg-0 col-lg-5 col-sm-4 col-xs-12' >


              {/* <h2 data-animation="animated slideInDown">
                Our vision  </h2> */}
              <div className='1wrapvh'>
                <h1 className="gridenttext colorh1">Intelligent<br /> Automation</h1>
                <p data-animation="animated slideInLeft mobtop50"> Intelligent automation, where algorithms reign, orchestrating tasks with precision, a digital domain. Efficiency's maestro, innovation's crown, it's the future's melody, where human and tech renown.</p></div>
              {/* mobile version show image */}
                 <Link to="/Cocreate">
                     <a
                        style={{
                            backgroundColor: '#000000', // Dark background
                            color: '#ffffff',           // White text
                            borderColor: '#ffffff',     // White border
                            borderWidth: '1px',         // Border width
                            borderStyle: 'solid',       // Border style
                            borderRadius: '10px',       // Rounded corners
                            padding: '15px 20px' ,
                            fontSize: '18px'
                        }}
                        data-animation="animated slideInUp">
                         Learn More
                     </a>
                 </Link>


              <img
                style={{ width: '100%', marginBottom: '3px', marginTop: '25px' }}
                src={release3mob}
                alt="Thumb" className='img-fluid no-shrink mobshow'
              />

              {/* mobile version show image end*/}
              {/* <h1 className="mb-2 text-black-401c gridenttext text-left">  Our vision </h1> */}
              {/* <p data-animation="animated slideInLeft">
                We envision a globally inclusive digital future, where our collaborative, innovative, and secure solutions bridge divides, foster sustainability, and transform businesses worldwide.
              </p> */}
              {/* <a
                className="btn-animation border dark smooth-menu"
                href="/Vision"
                data-animation="animated slideInUp"
              >
                Read more <FontAwesomeIcon icon={faArrowRightLong} />
              </a> */}
            </div>

            <div className='flex-item-slider imginfogra p25w offset-lg-0 col-lg-7 col-sm-5 col-xs-12'>

              <img
                style={{ width: '100%' }}
                src={release3}
                alt="Thumb" className='img-fluid no-shrink d-none d-sm-block'
              /></div>
          </SwiperSlide>

        </Swiper>
      </div>

    </div>


  )

}
export default BannerSlider;
